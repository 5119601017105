import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { 
    faDownload, 
    faArrowLeft, 
    faArrowRight, 
    faForwardStep, 
    faBackwardStep 
} from "@fortawesome/free-solid-svg-icons";
import toast, { Toaster } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "./Loader";

const Table = (props) => {
    const displaySize = 100;
    const [exportFilename, setExportFilename] = useState(null);
    const [displayData, setDisplayData] = useState(props.rows.slice(0, displaySize));
    const [currentPage, setCurrentPage] = useState(1);
    
    useEffect(() => {
        if(props.dateRange.from && props.dateRange.to) {
            const start = props.dateRange.from.toString().replaceAll("-", "");
            const end = props.dateRange.to.toString().replaceAll("-", "");
            setExportFilename(`${start}-${end}`);
        }
    }, [props.dateRange]);

    useEffect(() => {
        const startIndex = (currentPage - 1) * displaySize;
        const endIndex = currentPage * displaySize + 1;
        setDisplayData(props.rows.slice(startIndex, endIndex));
    }, [currentPage, props.rows]);

    const handleCopy = (text) => {
        if(window.isSecureContext) {
            navigator.clipboard.writeText(text).then(res => {
            toast("Copied to clipboard!", {position: "bottom-center"});
            })
        }
    }

    const TableNav = (props) => (
        <div className={props.className}>
            <button 
                className="disabled:text-[#BBBBBB]"
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(1)}
            >
                <FontAwesomeIcon icon={faBackwardStep} />
            </button>
            <button 
                className="disabled:text-[#BBBBBB]"
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(prevValue => prevValue - 1)}
            >
                <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <span className="min-w-[8rem] text-center">
                {(currentPage - 1) * displaySize + 1}
                &nbsp;-&nbsp;
                {Math.min(currentPage * displaySize, props.dataSize)}
                &nbsp;of&nbsp;
                {props.dataSize}
            </span>
            <button 
                className="disabled:text-[#BBBBBB]"
                disabled={currentPage === props.maxPage}
                onClick={() => setCurrentPage(prevValue => prevValue + 1)}
            >
                <FontAwesomeIcon icon={faArrowRight} />
            </button>
            <button 
                className="disabled:text-[#BBBBBB]"
                disabled={currentPage === props.maxPage}
                onClick={() => setCurrentPage(props.maxPage)}
            >
                <FontAwesomeIcon icon={faForwardStep} />
            </button>
        </div>
    )
    
    return (
        <React.Fragment>
            {props.loading ?
            <Loader className="h-80" text="Loading data..." />
            :
            <div className="table-wrapper h-80 overflow-scroll mx-auto">
                <table className="table-auto w-full overflow-x-scroll">
                    <thead className="sticky top-0 bg-primary">
                        <tr className="text-secondary">
                            {props.columns.map(column => (
                                <td 
                                    className={`p-2 bg-secondary text-primary opacity-60 whitespace-nowrap
                                        ${(column.key === 'transactionId' || column.key === 'walletAddress')
                                            && 'max-w-[150px]'
                                        }
                                    `}
                                >
                                    {column.label}
                                </td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {displayData.map((row, id) => (
                            <tr key={`row-${id}`} className="border-2">
                                {props.columns.map(column => (
                                    <td 
                                        className={`p-2 border-2 border-neutral-700 whitespace-nowrap -z-10 group
                                            ${(column.key === 'transactionId' || column.key === 'walletAddress')
                                            && 'max-w-[150px]'}
                                        `}
                                    >
                                        <div className="flex items-center">
                                            <span className="shrink w-full overflow-hidden text-ellipsis">{row[column.dataIndex]}</span>
                                            {(column.key === 'transactionId' || column.key === 'walletAddress')
                                                &&  <FontAwesomeIcon 
                                                    icon={faCopy}
                                                    onClick={() => handleCopy(row[column.key])} 
                                                    className="h-4 basis-6 z-[1050] cursor-pointer 
                                                    invisible text-secondary active:text-white
                                                    group-hover:visible" 
                                                />
                                            }
                                        </div>
                                        
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>}
            
            {!props.loading && 
                <React.Fragment>
                    <CSVLink 
                        data={props.rows} 
                        headers={props.columns}
                        filename={exportFilename}
                        onClick={(e) => {return !props.loading}}
                        className="absolute bottom-0 right-0 self-end flex items-center group 
                        bg-slate-500 p-1 z-10 opacity-50 hover:opacity-100 transition-all"
                    >
                        <FontAwesomeIcon icon={faDownload} className="m-1" />
                        <span 
                            className="text-xs whitespace-nowrap w-0 opacity-0 transition-[width] 
                            group-hover:w-fit group-hover:opacity-100"
                        >
                            Export as CSV
                        </span>
                    </CSVLink>
                    <TableNav 
                        className="absolute bottom-0 left-0 self-end flex items-center gap-3 
                        bg-slate-500 py-1 px-2 z-10 opacity-50 hover:opacity-100"
                        dataSize={props.rows.length}
                        maxPage={Math.ceil(props.rows.length / displaySize)}
                    />
                </React.Fragment>
            }
            <Toaster />
        </React.Fragment>
    )
}
export default Table;