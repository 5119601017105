import React from "react";

const Loader = (props) => {
    return (
        <div className={"flex justify-center items-center grow " + props.className}>
            <button type="button" className="flex gap-2 items-center px-5 py-2 rounded-lg text-white" disabled>
                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-30" cx="12" cy="12" r="10" stroke="#00FFEE" strokeWidth="4"></circle>
                    <path className="opacity-70" fill="#00FFEE" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                {props.text !== undefined ? props.text : "Loading..."}
            </button>
        </div>
    )
}

export default Loader;