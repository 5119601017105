import React, { useEffect, useState } from 'react';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import eachWeekOfInterval from 'date-fns/eachWeekOfInterval';
import eachMonthOfInterval from 'date-fns/eachMonthOfInterval';
import startOfWeek from 'date-fns/startOfWeek';
import format from 'date-fns/format';
import { GroupDataByLabel, splitDataBySwapType } from '../Utils/DataUtilities';
import Chart from './Chart';

const defaultDataDailyTrend = {sameChain: [], crossChain: [], all: []};

const Charts = (props) => {

    const [dataDailyTrend, setDataDailyTrend] = useState(defaultDataDailyTrend);
    const [dataCompareChains, setDataCompareChains] = useState({fromchain: [], tochain: []});
    const [dataUniqueWallets, setDataUniqueWallets] = useState(defaultDataDailyTrend);
    const [uniqueWalletsGroupKey, setUniqueWalletsGroupKey] = useState('week');
    const [compareTarget, setCompareTarget] = useState({key: "fromchain", name: "Origin Chain"});
    const [shownSwapTypes, setShownSwapTypes] = useState(["all", "sameChain", "crossChain"]);
    const [shownSwapTypesMAU, setShownSwapTypesMAU] = useState(["all", "sameChain", "crossChain"]);

    useEffect(() => {
        if (props.data) {
            const dataBySwapType = splitDataBySwapType(props.data);
            setDataDailyTrend(defaultDataDailyTrend);
            shownSwapTypes.map(key => {
                let groupedDaily = GroupDataByLabel(dataBySwapType[key], "createdDate");
                groupedDaily = fillMissingDataPoints(groupedDaily, 'day');
                const dataPoints = Object.values(groupedDaily);
                dataPoints.sort((x, y) => {
                    if (x.label > y.label) return 1;
                    if (x.label < y.label) return -1;
                    return 0;
                });
                setDataDailyTrend(prevData => ({
                    ...prevData,
                    [key]: dataPoints
                }));
            });
            shownSwapTypesMAU.map(key => {
                let groupedForUniqueWallets = GroupDataByLabel(dataBySwapType[key], uniqueWalletsGroupKey);
                console.log(groupedForUniqueWallets);
                groupedForUniqueWallets = fillMissingDataPoints(groupedForUniqueWallets, uniqueWalletsGroupKey);
                const dataPointsUniqueWallets = Object.values(groupedForUniqueWallets);
                setDataUniqueWallets(prevData => ({
                    ...prevData,
                    [key]: dataPointsUniqueWallets
                }));
            })
            const sanitizedData = props.data.filter(tx => !!tx.fromchain && !!tx.tochain);
            const origin = GroupDataByLabel(sanitizedData, "fromchain");
            const destination = GroupDataByLabel(sanitizedData, "tochain");
            setDataCompareChains({fromchain: Object.values(origin), tochain: Object.values(destination)});
            const end = new Date();
        }
    }, [props.data, shownSwapTypes, uniqueWalletsGroupKey]);

    // Fills missing days in data with 0 value for all fields
    const fillMissingDataPoints = (data, mode='day') => {
        const localData = {...data};
        let fillerFunction;
        if (mode === 'day') {
            fillerFunction = eachDayOfInterval;
        } 
        else if (mode === 'week') {
            fillerFunction = (interval) => {
                let labels = eachWeekOfInterval(interval);
                return labels.map(label => format(startOfWeek(label), 'y-MM-dd'));
            }
        }
        else {
            fillerFunction = (interval) => {
                let labels = eachMonthOfInterval(interval);
                return labels.map(label => {
                    const d = new Date(label);
                    return d.toLocaleString('en-IN', {month: 'short'});
                });
            }
        }
        const dataPointLabels = fillerFunction({
            start: props.dateRange.from,
            end: props.dateRange.to
        });
        if (mode === "week") {
            console.log(dataPointLabels, localData);
        }
        dataPointLabels.map(label => {
            const formatted = mode === 'day' ? format(label, 'y-MM-dd') : label;
            if (!localData[formatted]) {
                localData[formatted] = {
                    label: formatted,
                    totalVolume: 0,
                    count: 0,
                    activeWallets: 0,
                    totalVolumePercent: 0,
                    countPercent: 0
                };
            }
        });
        return localData;
    }

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 auto-rows-auto gap-5">
            <div className="p-5 custom-border relative overflow-clip">
                <Chart 
                    title={"Swap Types"}
                    data={{
                        sameChain: {name: "Same Chain", dataPoints: dataDailyTrend.sameChain},
                        crossChain: {name: "Cross Chain", dataPoints: dataDailyTrend.crossChain},
                        all: {name: "All Swaps", dataPoints: dataDailyTrend.all}
                    }}
                    type="line"
                    showInLegend={true}
                    columns={{
                        "totalVolume": "Volume", 
                        "count": "No. of Transactions",
                        "activeWallets": "Active Wallets"
                    }}
                    setShownSwapTypes={setShownSwapTypes}
                    loading={props.loading}
                />
            </div>
            <div className="p-5 custom-border relative overflow-clip">
                <Chart 
                    title={"Origin/Destination Chains Popularity"}
                    data={{
                        targetChain: {
                            name: compareTarget.name, 
                            dataPoints: dataCompareChains[compareTarget.key]
                        },
                    }}
                    type="column"
                    showInLegend={true}
                    columns={{
                        totalVolumePercent: "% Volume", 
                        countPercent: "% of Transactions",
                        totalVolume: "Volume",
                        count: "No. of Transactions"
                    }}
                    compareTarget={compareTarget}
                    setCompareTarget={setCompareTarget}
                    loading={props.loading}
                />
            </div>
            <div className="p-5 custom-border relative overflow-clip">
                <Chart 
                    title={"Weekly/Monthly Stats"}
                    data={{
                        sameChain: {name: "Same Chain", dataPoints: dataUniqueWallets.sameChain},
                        crossChain: {name: "Cross Chain", dataPoints: dataUniqueWallets.crossChain},
                        all: {name: "All Swaps", dataPoints: dataUniqueWallets.all}
                    }}
                    type="line"
                    showInLegend={true}
                    columns={{
                        "totalVolume": "Volume", 
                        "count": "No. of Transactions",
                        "activeWallets": "Active Wallets"
                    }}
                    setShownSwapTypes={setShownSwapTypesMAU}
                    setUniqueWalletsGroupKey={setUniqueWalletsGroupKey}
                    loading={props.loading}
                />
            </div>
        </div>
    )
}

export default Charts;