import axios from 'axios';



export const swapDashboard = async () => {
    const swapValue = await axios.get("https://ag.kanalabs.io/referral/fetchDashboardSwap");
    return swapValue.data;
}

export const userLogin = async (receiveddata) => {
    const swapValue = await axios.post(
            "https://ag.kanalabs.io/dashboard/admin/login",receiveddata
        );
    return swapValue.data;
}

export const fetchReportByDateRange = async (data) => {
    try {
        const response = await axios.post(
            "https://ag.kanalabs.io/dashboard/admin/transactionHistoty", 
            data
        );
        if (response.status != 200 || response.data.error)
        {
            console.log(response);
            return;
        }
        return response.data;
    }
    catch(err) {
        console.log(err);
    }
    
}