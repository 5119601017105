import React from "react";
import Loader from "../Components/Loader";

const ScoreCard = (props) => {
    return (
        <div className={`flex flex-col items-center px-5 ${props.className}`}>
            {props.loading ?
                <Loader text=" " className="leading-10" />
                :
                <h2 className="text-3xl lg:text-4xl font-bold text-secondary">
                    {!!props.prefix ? <sup className="text-xl lg:text-3xl">{props.prefix}&nbsp;</sup> : ""}
                    {props.value}
                </h2>
            }
            <p>{props.name}</p>
        </div>
    )
}

export default ScoreCard;