import React, { useEffect, useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;
const defaultSwapTypes = [
    {key: "all", label: "All Swaps", checked: true},
    {key: "sameChain", label: "Same Chain", checked: true},
    {key: "crossChain", label: "Cross Chain", checked: true},
]

const Chart = (props) => {
    
    const [chart, setChart] = useState(null);
    const [dataPoints, setDataPoints] = useState(null);
    const [colYKey, setColYKey] = useState(Object.keys(props.columns)[0]);
    const [chartOptions, setChartOptions] = useState({
        animationEnabled: true,
        title: { text: props.title },
        axisY: { title: props.columns[colYKey] },
        toolTip: { shared: true },
        data: []
    });
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [swapTypes, setSwapTypes] = useState(defaultSwapTypes);

    useEffect(() => {
        if (chart) {
            chart.themeOptions.backgroundColor = "transparent";
            chart.themeOptions.title.fontColor = "white";
            chart.themeOptions.title.fontSize = 20;
            chart.toolTip.fontColor = "white";
            chart.toolTip.fontWeight = "bold";
            chart.toolTip.backgroundColor = "#085E59CC";
            chart.themeOptions.axisX[0].titleFontColor = "white";
            chart.themeOptions.axisX[0].labelFontColor = "white";
            chart.themeOptions.axisY[0].titleFontColor = "white";
            chart.themeOptions.axisY[0].labelFontColor = "white";
            chart.themeOptions.legend.fontColor = "white";
            chart.themeOptions.legend.verticalAlign = "top";
        }
    }, [chart]);

    useEffect(() => {
        Object.keys(props.data).map(dataKey => {
            const dataPoints = props.data[dataKey].dataPoints.map(tx => ({
                y: tx[colYKey],
                label: tx.label
            }));
            setDataPoints(prevDP => ({
                ...prevDP,
                [dataKey]: dataPoints
            }));
        });
    }, [colYKey, props.data]);

    useEffect(() => {
        if (dataPoints) {
            const data = [];
            Object.keys(dataPoints).map(dpKey => {
                data.push({
                    type: props.type,
                    name: props.data[dpKey].name,
                    showInLegend: props.showInLegend,
                    toolTipContent: props.toolTipContent,
                    // legendText: props.legendText,
                    // indexLabel: "{y}%",
                    dataPoints: dataPoints[dpKey]
                })
            });
            setChartOptions(prevOptions => ({
                ...prevOptions,
                axisY: { title: props.columns[colYKey] },
                toolTip: {
                    content: `{label}: {y}${colYKey.includes("Percent") ? "%": ""}`
                },
                data: data
            }));
        }
    }, [dataPoints]);

    useEffect(() => {
        if (!props.setShownSwapTypes) return;
        console.log(swapTypes, props.setShownSwapTypes);
        const checkedSwapTypes = swapTypes.filter(st => st.checked);
        props.setShownSwapTypes(checkedSwapTypes.map(st => st.key));
    }, [swapTypes]);

    const handleColumnSelect = (e) => {
        setColYKey(e.target.value);
        setPopoverOpen(false);
    }

    const handleChainTypeSelect = (e) => {
        props.setCompareTarget({
            name: e.target.parentNode.textContent,
            key: e.target.value
        });
        setPopoverOpen(false);
    }

    const handleSwapTypeChange = (e) => {
        setSwapTypes(prevST => {
            return prevST.map(st => ({
                ...st,
                checked: st.key === e.target.value ? !st.checked : st.checked
            }));
        });
    }

    const handleSettingsClick = () => {
        setPopoverOpen(prevState => !prevState);
    }

    // Setting the z-index of this element huge, because ChartJS assigns an atrocious 1000 to its toolTip.
    const SettingsPopOver = (
        <form className="absolute right-0 bottom-8 opacity-80 bg-[#420355] text-secondary text-xs p-2 z-[1010]">
            <p>Choose column for Y-axis</p>
            {Object.entries(props.columns).map(([key, label]) => (
                <label className="flex items-center gap-2 p-1" key={key}>
                    <input 
                        className="text-[#00FFEE55]" 
                        type="radio" 
                        name="colY" 
                        value={key}
                        onChange={handleColumnSelect}
                        checked={colYKey === key}
                    />
                    {label}
                </label>
            ))}
            {props.compareTarget && <React.Fragment>
            <hr className="my-2"/>
            <p>Choose chain type to compare</p>
            <label className="flex items-center gap-2 p-1">
                <input 
                    className="text-[#00FFEE55]" 
                    type="radio" 
                    name="chainType" 
                    value="fromchain"
                    onChange={handleChainTypeSelect}
                    checked={props.compareTarget.key === "fromchain"}
                />
                Origin Chain
            </label>
            <label className="flex items-center gap-2 p-1">
                <input 
                    className="text-[#00FFEE55]" 
                    type="radio" 
                    name="chainType" 
                    value="tochain"
                    onChange={handleChainTypeSelect}
                    checked={props.compareTarget.key === "tochain"}
                />
                Destination Chain
            </label></React.Fragment>}
            {props.title === "Swap Types" && <React.Fragment>
                <hr className="my-2"/>
                <p>Show swap types</p>
                {swapTypes.map((swap) => (
                    <label key={swap.key} className="flex items-center gap-2 p-1">
                        <input 
                            className="text-[#00FFEE55]" 
                            type="checkbox" 
                            name="swapType" 
                            value={swap.key}
                            onChange={handleSwapTypeChange}
                            checked={swap.checked}
                        />
                        {swap.label}
                    </label>
                ))}
            </React.Fragment>}
            {props.title === "Weekly/Monthly Stats" && <React.Fragment>
                <hr className="my-2"/>
                <p>Show frequency</p>
                <label className="flex items-center gap-2 p-1">
                    <input 
                        className="text-[#00FFEE55]" 
                        type="radio" 
                        name="frequency" 
                        value="week"
                        onChange={() => {props.setUniqueWalletsGroupKey('week')}}
                    />
                    Weekly
                </label>
                <label className="flex items-center gap-2 p-1">
                    <input 
                        className="text-[#00FFEE55]" 
                        type="radio" 
                        name="frequency" 
                        value="month"
                        onChange={() => {props.setUniqueWalletsGroupKey('month')}}
                    />
                    Monthly
                </label>
            </React.Fragment>}
        </form>
    )

    return (
        <React.Fragment>
            {props.loading ?
                <Loader text="Loading data..." className="h-[300px]" />
                :
                <React.Fragment>
                    <CanvasJSChart 
                        options={chartOptions} 
                        onRef={ref => setChart(ref)} 
                        containerProps={{height: "300px"}} 
                    />
                    <button 
                        className={`absolute right-0 bottom-0 bg-slate-500 opacity-50 hover:opacity-100 
                        transition-all group ${popoverOpen && "opacity-100"}`}
                        onClick={handleSettingsClick}
                    >
                        <FontAwesomeIcon 
                            icon={faGear} 
                            className={`m-1.5 group-hover:animate-spin-slow ${popoverOpen && "animate-spin-slow"}`}
                        />
                    </button>
                    {popoverOpen && SettingsPopOver}
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default Chart;