import format from "date-fns/format";

export const GroupDataByLabel = (data, labelKey) => {
    let grouped = {};
    const activeWallets = {};
    data.map((tx) => {
        const label = resolveLabel(tx[labelKey]);
        if (!grouped[label]) 
        {
            grouped[label] = {
                label: label, 
                totalVolume: tx.totalVolume, 
                count: 1,
                activeWallets: 1
            }
            activeWallets[label] = [tx.walletAddress];
        }
        else
        {
            grouped[label].totalVolume += tx.totalVolume;
            grouped[label].count += 1;
            if (activeWallets[label].indexOf(tx.walletAddress) < 0) {
                activeWallets[label].push(tx.walletAddress);
                grouped[label].activeWallets++;
            }
        }
        const aggregateVolume = Object.keys(grouped).reduce((totalVolume, label) => totalVolume + grouped[label].totalVolume, 0);
        Object.keys(grouped).forEach(label => {
            grouped[label].totalVolumePercent = Math.round(grouped[label].totalVolume / aggregateVolume * 10000) / 100;
            grouped[label].countPercent = Math.round(grouped[label].count / data.length * 10000) / 100;
        });
    });
    return grouped;
}

export const splitDataBySwapType = (data) => {
    const dataBySwapType = {sameChain: [],  crossChain: [], all: []};
    dataBySwapType.all = [...data];
    dataBySwapType.sameChain = data.filter(tx => {
        if (tx.transactionType === "crosschainswap") {
            dataBySwapType.crossChain.push(tx);
            return false;
        }
        else {
            return true;
        }
    });
    return dataBySwapType;
}

const fromISOToDateString = (isoDateString) => {
    return format(new Date(isoDateString), 'y-MM-dd');
}

const resolveLabel = (string) => {
    if (isNaN(new Date(string))) {
        console.log('Not a date string. Returning', string)
        return string;
    }
    else {
        console.log(string, "is a date string. Returning", fromISOToDateString(string));
        return fromISOToDateString(string);
    }
}