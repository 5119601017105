import React, { useState, useEffect } from 'react';
import DateRangePicker from '../Components/DateRangePicker';
import { fetchReportByDateRange } from '../Utils/APIHelper';
import ScoreCard from '../Components/ScoreCard';
import kanalabslogo from '../Assests/kanalabslogo.png';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import Table from '../Components/Table';
import Charts from '../Components/Charts';
import { addDays, subDays, format, getWeek, startOfWeek } from 'date-fns';
import { useCookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-hot-toast';
import { faFaceDizzy } from '@fortawesome/free-solid-svg-icons';

const today = new Date();
const defaultRange = {from: subDays(today, 6), to: today}

const Dashboard = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['userToken']);
    const [scorecardDateRange, setScorecardDateRange] = useState("selected");
    const [aggregateData, setAggregateData] = useState({"selected": {}, "allTime": {}});
    const [rows, setRows] = useState([]);
    const [chartData, setChartData] = useState(null);
    const [dateRange, setDateRange] = useState(defaultRange);
    const [loading, setLoading] = useState(false);
    const [scoreCardLoading, setScoreCardLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const columns = [
        {label: "Date & Time", dataIndex: "createdDate", key: "createdDate"},
        {label: "Token Name", dataIndex: "tokenName", key: "tokenName"},
        {label: "Transaction ID", dataIndex: "transactionId", key: "transactionId"},
        {label: "Wallet Address", dataIndex: "walletAddress", key: "walletAddress"},
        {label: "Volume", dataIndex: "totalVolume", key: "totalVolume"},
        {label: "From Chain", dataIndex: "fromchain", key: "fromchain"},
        {label: "To Chain", dataIndex: "tochain", key: "tochain"},
        {label: "Transaction Type", dataIndex: "transactionType", key: "transactionType"}
    ]

    useEffect(() => {
        if (dateRange.from && dateRange.to) loadData();
    }, [dateRange]);

    useEffect(() => {
        getAllTimeData();
    }, [])

    const getAllTimeData = async () => {
        setScoreCardLoading(true);
        const startDate = new Date("2022-12-01");
        let data;
        try {
            data = await getReportByDateRange(startDate, today);
        } catch (err) {
            console.log(err);
        }
        calculateAggregateData(data, "allTime");
        setScoreCardLoading(false);
    }

    const logout = async () => {
        removeCookie("userToken");
    }

    const calculateAggregateData = (data, reportType) => {
        const activeWallets = [];
        data.map(tx => {
            if (activeWallets.indexOf(tx.walletAddress) < 0) {
                activeWallets.push(tx.walletAddress);
            }
        });
        const aggregateVolume = data.reduce((totalVolume, tx) => totalVolume + tx.totalVolume, 0);
        setAggregateData(prevData => ({
            ...prevData,
            [reportType]: {
                count: data.length.toLocaleString('en'),
                volume: parseFloat(aggregateVolume.toFixed(3)).toLocaleString('en'),
                fee: parseFloat((aggregateVolume / 1000).toFixed(3)).toLocaleString('en'),
                activeWallets: activeWallets.length.toLocaleString('en')
            }
        }));
    }

    const getReportByDateRange = async (startDate, endDate) => {
        let requestPage = 0;
        let endOfData = false;
        let transactions = [];
        setLoading(true);
        if (isError) { setIsError(false); }
        do {
            let response = await fetchReportByDateRange({
                transactionType: "all",
                fromDate: format(startDate, 'y-MM-dd'),
                toDate: format(endDate, 'y-MM-dd'),
                pagination: requestPage
            });
            if (!response.data) {
                setIsError(true);
                setLoading(false);
                setScoreCardLoading(false);
                return;
            }
            transactions.push(...response.data);
            if (!response.isPagination) endOfData = true;
            else if (response.isPagination && response.length < 5000) endOfData = true;
            requestPage++;
        } while (!endOfData);
        return transactions;
    }

    const loadData = async () => {
        const transactions = await getReportByDateRange(dateRange.from, dateRange.to);
        transactions.sort((x, y) => {
            if (x.createdDate > y.createdDate) return 1;
            if (x.createdDate < y.createdDate) return -1;
            return 0;
        });
        transactions.forEach(tx => {
            const cdate = new Date(tx.createdDate);
            tx.week = format(startOfWeek(cdate), 'y-MM-dd');
            tx.month = cdate.toLocaleString('en-IN', {month: 'short'});
        })
        setChartData(transactions);
        calculateAggregateData(transactions, "selected");
        const rows = [...transactions];
        rows.forEach(tx => {
            tx.createdDate = format(new Date(tx.createdDate), 'y-MM-dd HH:mm');
        });
        setRows(rows);
        setLoading(false);
    }

    const resetDateRange = () => {
        setDateRange(defaultRange);
    }

    const NoData = (
        <div className='flex flex-col gap-2 justify-center items-center h-full opacity-50'>
            <FontAwesomeIcon 
                icon={faFaceDizzy} 
                className='fa-5x fa-shake' 
                style={{"--fa-animation-iteration-count": 1}}
            />
            <h1 className='text-5xl mb-4'>Yikes!</h1>
            <p>We seem to have hit a snag. Wanna try again?</p>
            <button className='text-xs text-blue-400 underline' onClick={resetDateRange}>
                No, take me back to the beginning!
            </button>
        </div>
    )

    return (
        <div className='lg:h-screen p-5 flex flex-col lg:flex-row gap-5 text-white'>
            <section className='sidebar flex flex-row lg:flex-col shrink-0 justify-between custom-border p-5'>
                <img src={kanalabslogo} alt='KanaSwap logo' className='h-6' />
                <button 
                    className='text-white flex items-center justify-center w-fit lg:w-full h-5 brightness-75 hover:brightness-200 transition-all'
                    onClick={logout}
                >
                    Logout
                    <FontAwesomeIcon icon={faRightFromBracket} className='h-full ml-2' />
                </button>
            </section>
            <section className='main h-full flex flex-col grow gap-5 overflow-y-auto no-scrollbar'>
                <div className='action-bar flex max-[568px]:flex-col justify-between items-center custom-border py-2 px-5'>
                    Welcome, KanaLabs Admin!
                    <DateRangePicker
                        selected={dateRange}
                        onSelect={setDateRange}
                        reset={resetDateRange}
                    />
                </div>
                {isError ?
                    NoData
                    :
                    <React.Fragment>
                        <div className='custom-border'>
                            <div className='flex justify-center gap-4'>
                                <span 
                                    onClick={() => setScorecardDateRange("allTime")}
                                    className={`cursor-pointer ${scorecardDateRange == "allTime" && "text-secondary border-b-2 border-secondary"}`}
                                >
                                    All Time
                                </span>
                                <span 
                                    onClick={() => setScorecardDateRange("selected")}
                                    className={`cursor-pointer ${scorecardDateRange == "selected" && "text-secondary border-b-2 border-secondary"}`}
                                >
                                    Selected
                                </span>
                            </div>
                            <div className='action-bar grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 py-2 xl:divide-x gap-y-5 xl:gap-0'>
                                <ScoreCard 
                                    value={aggregateData[scorecardDateRange].count} 
                                    name={"Transactions"} 
                                    loading={scoreCardLoading}
                                />
                                <ScoreCard 
                                    value={aggregateData[scorecardDateRange].volume} 
                                    name={"Total Volume"} prefix="$" 
                                    loading={scoreCardLoading}
                                />
                                <ScoreCard 
                                    value={aggregateData[scorecardDateRange].fee} 
                                    name={"Kana Fees"} 
                                    loading={scoreCardLoading}
                                />
                                <ScoreCard 
                                    className="lg:max-xl:col-span-3"
                                    value={aggregateData[scorecardDateRange].activeWallets} 
                                    name={"Active Wallets"} 
                                    loading={scoreCardLoading}
                                />
                            </div>
                        </div>
                        <Charts data={chartData} dateRange={dateRange} loading={loading} />
                        <div className='data-board custom-border overflow-clip relative'>
                            <Table columns={columns} rows={rows} dateRange={dateRange} loading={loading} />
                        </div>
                    </React.Fragment>
                }
            </section>
        </div >

    )
}

export default Dashboard