import React from 'react'
import Dashboard from './Pages/Dashboard';
import Dashboard2 from './Pages/DashboardNew';
import Adminpage from './Pages/Adminpage';
import { BrowserRouter, Routes, Route,useNavigate} from "react-router-dom";
import { CookiesProvider } from 'react-cookie';
import PrivateRoute from './Components/PrivateRoute';

function App() {
  // const navigate=useNavigate();

  return (
    <CookiesProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Adminpage/> } />
          <Route path="/dashboard" element={<PrivateRoute><Dashboard/></PrivateRoute>} />
          <Route path="/dashboard2" element={<PrivateRoute><Dashboard2/></PrivateRoute>} />
          <Route path="/" to='/login' element={<Adminpage />} />
        </Routes>
      </BrowserRouter>
    </CookiesProvider>
    


  );
}

export default App;
