import React, { useState, useRef, useEffect} from "react";
import { DayPicker } from "react-day-picker";
import { subDays, format, isAfter, differenceInCalendarDays, addDays, isBefore, startOfToday, subMinutes } from "date-fns";
import { usePopper } from "react-popper";
import 'react-day-picker/dist/style.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay, faTrash, faSync } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-hot-toast";

const hoveredStyles = {
    color: '#00FFEE88',
    backgroundColor: '#00FFEE55',
    borderRadius: 0
}
const selectedStyles = {
    color: '#1C0124',
    borderRadius: 0
}

const tzOffset = new Date().getTimezoneOffset();
const today = subMinutes(startOfToday(), tzOffset);

const DateRangePicker = (props) => {
    
    const popperRef = useRef(null);
    const buttonRef = useRef(null);

    const [dateInput, setDateInput] = useState("");
    const [popperElement, setPopperElement] = useState(null);
    const [isPopperOpen, setIsPopperOpen] = useState(false);
    const [hoveredRange, setHoveredRange] = useState([]);
    const [hoveredDay, setHoveredDay] = useState(null);

    const popper = usePopper(popperRef.current, popperElement, {
        placement: "bottom-end"
    });

    useEffect(() => {
        if (props.selected)
        {
            setDateInput(rangeToInput(props.selected));
            props.onSelect(props.selected);
        }
    }, [props.selected]);

    const rangeToInput = (range) => {
        const from = range.from ? format(range.from, "y-MM-dd") : "";
        const to = range.to ? format(range.to, "y-MM-dd") : "";
        return `${from}~${to}`;
    }

    const handleButtonClick = () => {
        setIsPopperOpen(prevState => !prevState);
    }

    const handleSelect = (range) => {
        if (range.to) {
            setIsPopperOpen(false);
        }
        props.onSelect(range);
    }

    const clearSelection = () => {
        props.onSelect({from: undefined, to: undefined});
    }

    const handleReset = () => {
        props.reset();
        setIsPopperOpen(false);
    }

    const handleMouseEnter = (day, modifiers, e) => {
        if (!day) return;
        let newHoveredRange = [];
        let anchor = props.selected.to ? props.selected.to : props.selected.from;
        if (isAfter(day, anchor) || isAfter(day, props.selected.from)) {
            const diff = differenceInCalendarDays(day, anchor);
            for (let i = 0; i < diff; i++) {
                newHoveredRange.push(subDays(day, i));
            }
        }
        if (isBefore(day, props.selected.from)) {
            const diff = differenceInCalendarDays(props.selected.from, day)
            for (let i = 0; i < diff; i++) {
                newHoveredRange.push(addDays(day, i));
            }
        }
        if (isAfter(day, props.selected.from) && isBefore(day, props.selected.to)) {
            const diff = differenceInCalendarDays(day, props.selected.from);
            for (let i = 0; i <= diff; i++) {
                newHoveredRange.push(subDays(day, i));
            }
        }
        setHoveredRange(newHoveredRange);
        setHoveredDay(day);
    }

    const handleMouseLeave = () => {
        setHoveredDay(null);
        setHoveredRange([]);
    }

    const handleClickAway = (e) => {
        if (e.relatedTarget !== null) {
            // console.log(e.relatedTarget);
            return;
        }
        setIsPopperOpen(false);
    }

    const handleManualSubmit = (e) => {
        e.preventDefault();
        const [from, to] = dateInput.split('~').map(d => new Date(d));
        if(from.toString() === "Invalid Date" || to.toString() === "Invalid Date") {
            toast.error("Please enter a valid date range!");
            setDateInput(rangeToInput(props.selected));
            return;
        }
        if (isAfter(from, to)) {
            toast.error("Start date cannot be bigger than end date!");
            return;
        }
        if (isAfter(from, today) || isAfter(to, today)) {
            toast.error("Date range cannot contain future dates!");
            return;
        }
        const range = { from, to };
        props.onSelect(range);
        e.target.firstChild.blur();
        setTimeout(() => setIsPopperOpen(false), 5);
    }

    const handleManualChange = (e) => {
        setDateInput(e.target.value);
    }

    const footer = (
        <div className="flex justify-end">
            <button className="group flex items-center gap-1 text-sm text-amber-400 px-2 py-1" onClick={clearSelection}>
                <FontAwesomeIcon icon={faTrash} className="group-active:animate-wiggle-hard" />
                Clear
            </button>
            <button className="group flex items-center gap-1 text-sm text-blue-300 px-2 py-1" onClick={handleReset}>
                <FontAwesomeIcon icon={faSync} className="group-active:animate-wiggle-hard" />
                Reset to Default
            </button>
        </div>
    );

    return (
        <React.Fragment>
            <form 
                ref={popperRef} 
                className="relative max-[568px]:w-full my-3 group"
                onSubmit={handleManualSubmit}
            >
                <input 
                    type="text" 
                    className="bg-primary max-[568px]:w-full border-[1px] 
                    border-[#00FFEE88] py-1.5" 
                    value={dateInput} 
                    onChange={handleManualChange}
                />
                <button
                    ref={buttonRef}
                    type="button"
                    className="h-full button-reset absolute right-0"
                    aria-label="Pick a date range"
                    onClick={handleButtonClick}
                >
                    <FontAwesomeIcon 
                        icon={faCalendarDay} 
                        className="h-[20px] text-[#00FFEE88] p-1 m-1 active:animate-wiggle"
                    />
                </button>
            </form>
            {isPopperOpen &&
            <div
                tabIndex={-1}
                style={popper.styles.popper}
                className="dialog-sheet bg-primary z-10 opacity-90 border-[1px] border-[#00FFEE88]"
                {...popper.attributes.popper}
                ref={setPopperElement}
                onBlur={handleClickAway}
                role="dialog"
                aria-label="DayPicker calendar"
            >
                <DayPicker
                    mode="range"
                    initialFocus={isPopperOpen}
                    defaultMonth={props.selected.from}
                    numberOfMonths={2}
                    selected={props.selected}
                    onSelect={handleSelect} 
                    onDayMouseEnter={handleMouseEnter}
                    onDayMouseLeave={handleMouseLeave}
                    disabled={day => day > (new Date())}
                    modifiers={{
                        inHoverRange: hoveredRange,
                        hoveredDay: hoveredDay
                    }}
                    modifiersStyles={{
                        inHoverRange: hoveredStyles,
                        selected: selectedStyles
                    }}
                />
                {footer}
            </div>
                
            }
        </React.Fragment>
    )
}

export default DateRangePicker;