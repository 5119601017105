import React, { useState, useEffect, useRef } from 'react';
import kanalabslogo from '../Assests/kanalabslogo.png';
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom"
import { userLogin } from '../Utils/APIHelper';
import { toast, Toaster } from 'react-hot-toast';
import Loader from '../Components/Loader';

const Adminpage = () => {
    const navigate = useNavigate();
    const emailRef = useRef(null);
    const pwRef = useRef(null);
    const [cookies, setCookie, removeCookie] = useCookies(['userToken']);
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (cookies.userToken) { navigate("/dashboard2"); }
        if (emailRef.current) emailRef.current.focus();
    }, []);

    const validate = () => {
        if (!formData.email) {
            toast.error("Please provide valid email!");
            emailRef.current.focus();
            return false;
        }
        if (!formData.password) {
            toast.error("Please provide valid password!");
            pwRef.current.focus();
            return false;
        }
        return true;
    }

    const login = async () => {
        if (!validate()) return;
        setLoading(true);
        const response = await userLogin(formData);
        if (response.error) {
            toast.error(response?.message)
        } else {
            setCookie('userToken', response.data);
            navigate('/dashboard2');
        }
        setLoading(false);
    }

    const handleChange = ({target: {id: id, value: value}}) => {
        setFormData(prevData => ({
            ...prevData,
            [id]: value
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        login();
    }

    return (
        <div className='flex flex-col h-full'>
            <header className='grid basis-4 justify-center mt-5'>
                <img src={kanalabslogo} alt='' className='mt-5 items-center' />
            </header>
            {loading ?
                <Loader text="Logging you in..." />
                :
                <section className='flex flex-col items-center'>
                    <h1 className='text-6xl text-secondary font-rajd mt-28'>Admin</h1>
                    <p className='text-white mt-3 font-raj'>
                        Enter Your Login id and Password
                    </p>
                    <form className='flex flex-col items-center gap-6 mt-2' onSubmit={handleSubmit}>
                        <input 
                            ref={emailRef}
                            type="email"
                            id="email"
                            placeholder="Email" 
                            className='rounded-lg w-72 text-primary' 
                            onChange={handleChange}
                            autoComplete='off'
                        />
                        <input 
                            ref={pwRef}
                            type="password"
                            id="password"
                            placeholder="Password" 
                            className='rounded-lg w-72 text-primary' 
                            onChange={handleChange}
                        />               
                        <button 
                            type='submit'
                            className='bg-gradient-light px-6 py-2 w-fit rounded-full mt-4 font-bold'
                        >
                            Login
                        </button>
                    </form>
                </section>
            }
            <Toaster />
        </div >
    )
}

export default Adminpage
